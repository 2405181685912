<template>
	<div contenteditable="true" v-once v-html="value" :value="value" @input="$emit('input', $event.target.innerHTML)"></div>
</template>
<script>
	export default {
		props: ['value'],
		watch: {
			value(newValue) {
				if (document.activeElement == this.$el) {
					return;
				}

				this.$el.innerHTML = newValue;
			}
		}

	}
</script>